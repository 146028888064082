import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { IconTypes } from '../Icon/Icon';
import './style.scss';
import { SalesPersonContact } from '../../data/broker-center/contactTypes';
import SmallImage from '../Image/SmallImage/SmallImage';
import ExternalLink from '../ExternalLink/ExternalLink';
import { LocaleNameSpaceKeys } from '../../localization/translations';

export interface OwnProps {
  className?: string;
}

type Props = OwnProps & SalesPersonContact;

const ContactCard: React.FC<Props> = ({
  className = '',
  profileImageFileName = '', // should always be present for this component
  name,
  jobTitleKey,
  number,
  tollFreeNumber = '',
  email,
  territoryKey = '',
}: Props) => {
  const { t } = useTranslation();

  const ariaLabels = {
    email: t(`${LocaleNameSpaceKeys.COMMON}:email`),
    phoneNumber: t(`${LocaleNameSpaceKeys.COMMON}:phoneNumber`),
    tollFree: t(`${LocaleNameSpaceKeys.COMMON}:tollFree`),
  };

  return (
    <div className={`ContactCard ${className}`}>
      <div className="ContactCard__contact-profile-image">
        {profileImageFileName && (
          <SmallImage fileName={profileImageFileName} alt={`${name} image`} />
        )}
      </div>
      <div className="ContactCard__contact-info">
        <p className="h5">{`${name}`}</p>
        <p>{`${t(jobTitleKey)}`}</p>
        <div className="ContactCard__icon-with-text">
          <Icon type={IconTypes.SMART_PHONE} />
          <span>
            {`${t('mobile')}`}:{' '}
            <ExternalLink href={`tel:${number}`} aria-label={`${ariaLabels.phoneNumber} ${number}`}>
              {number}
            </ExternalLink>
          </span>
        </div>
        {tollFreeNumber && (
          <div className="ContactCard__icon-with-text">
            <Icon type={IconTypes.PHONE} />
            <span>
              {`${t('tollFree')}`}:{' '}
              <ExternalLink
                href={`tel:${tollFreeNumber}`}
                aria-label={`${ariaLabels.tollFree} ${tollFreeNumber}`}
              >
                {tollFreeNumber}
              </ExternalLink>
            </span>
          </div>
        )}
        <div className="ContactCard__icon-with-text">
          <Icon type={IconTypes.EMAIL_AT_SIGN} />
          <span>
            <ExternalLink href={`mailto:${email}`} aria-label={`${ariaLabels.email} ${email}`}>
              {email}
            </ExternalLink>
          </span>
        </div>
        {territoryKey && (
          <div className="ContactCard__icon-with-text">
            <Icon type={IconTypes.LOCATION_PIN} />
            <span>
              {`${t('territory')}`}: {t(territoryKey)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ContactCard);
