import { SalesPersonContacts } from './contactTypes';

const contacts: SalesPersonContacts = [
  {
    profileImageFileName: 'SusanThomas.png',
    name: 'Susan Thomas',
    jobTitleKey: 'jobTitles.presidentNationalSalesF',
    territoryKey: 'territories.national',
    email: 'susan.thomas@haventreebank.com',
    number: '647.453.0839',
  },
  {
    profileImageFileName: 'SandyPandolfi.png',
    name: 'Sandy Pandolfi',
    jobTitleKey: 'jobTitles.businessDevelopmentManagerF',
    territoryKey: 'territories.southwestOntario',
    email: 'spandolfi@haventreebank.com',
    number: '647.333.3246',
  },
  {
    profileImageFileName: 'MarshallSpencer.png',
    name: 'Marshall Spencer',
    jobTitleKey: 'jobTitles.businessDevelopmentManager',
    territoryKey: 'territories.GTAEast',
    email: 'marshall.spencer@haventreebank.com',
    number: '647.217.2516',
  },
  {
    profileImageFileName: 'JimFitzGerald.png',
    name: 'Jim FitzGerald',
    jobTitleKey: 'jobTitles.businessDevelopmentManager',
    territoryKey: 'territories.GTAWest',
    email: 'james.fitzgerald@haventreebank.com',
    number: '416.717.8469',
  },
  {
    profileImageFileName: 'JuthisKuper.png',
    name: 'Juthis Kuper',
    jobTitleKey: 'jobTitles.businessDevelopmentManager',
    territoryKey: 'territories.gtaCentral',
    email: 'juthis.kuper@haventreebank.com',
    number: '437.223.2433',
  },
  {
    profileImageFileName: 'MariaBolarinho.png',
    name: 'Maria Bolarinho',
    jobTitleKey: 'jobTitles.businessDevelopmentManagerF',
    territoryKey: 'territories.greaterMontrealArea',
    email: 'mbolarinho@banquehaventree.com',
    number: '438.870.3056',
  },
  {
    profileImageFileName: 'BrettLovegrove.png',
    name: 'Brett Lovegrove',
    jobTitleKey: 'jobTitles.businessDevelopmentManager',
    territoryKey: 'territories.albertaBCInterior',
    email: 'blovegrove@haventreebank.com',
    number: '403.815.1159',
  },
  {
    profileImageFileName: 'MeghanFarrant.png',
    name: 'Meghan Farrant',
    jobTitleKey: 'jobTitles.businessDevelopmentManagerF',
    territoryKey: 'territories.greaterVancouverAreaVancouverIsland',
    email: 'meghan.farrant@haventreebank.com ',
    number: '604.838.0151',
  },
  {
    profileImageFileName: 'JohnMcGuire.jpg',
    name: 'John McGuire',
    jobTitleKey: 'jobTitles.businessDevelopmentManager',
    territoryKey: 'territories.saskatchewanManitoba',
    email: 'john.mcguire@haventreebank.com',
    number: '306.717.4986',
  },
  {
    profileImageFileName: 'JacklynSparling.png',
    name: 'Jacklyn Sparling',
    jobTitleKey: 'jobTitles.businessDevelopmentManagerF',
    territoryKey: 'territories.ontarioEast',
    email: 'jacklyn.sparling@haventreebank.com',
    number: '647.403.6733',
  },
];

export default contacts;
